import React from "react";
import styled from "styled-components";
import { ButtonUI, FontMixinUI } from "@ui";
import { AdaptiveLinkCt } from "@components";
import { TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import coin from '@images/career/coin.webp';

export const CareerBannerWantInTeam = () => (
  <Section>
    <Main>
      <Title>
        <span>Хотите стать частью команды?</span>
        <span>Присылайте резюме по адресу</span>
      </Title>

      <Links>
        <LinkMail to="mailto:hr@selfwork.ru">hr@selfwork.ru</LinkMail>

        <AdaptiveLinkCt to="https://hh.ru/employer/4152303">
          <ButtonUI $w="276px">Смотреть вакансии</ButtonUI>
        </AdaptiveLinkCt>
      </Links>
    </Main>
  </Section>
)

const Section = styled.section`
  display: flex;
  justify-content: center;

  margin-left: -16px;
  margin-right: -16px;

  background: var(--background-second, #E5F2FF);
  overflow: hidden;

  @media (min-width: 605px) {
    border-radius: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    justify-content: end;

    height: 338px;

    background-image: url(${coin});
    background-size: 256px 286px;
    background-repeat: no-repeat;
    background-position: 141px bottom;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 52px 30px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    justify-content: center;
    width: 628px;
  }
`

const Title = styled(TypographyUI.H4Bold)`
  display: flex;
  flex-direction: column;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.25rem;
`

const LinkMail = styled(AdaptiveLinkCt)`
  ${FontMixinUI.CirceNormal}
  font-size: 22px;
  line-height: 32px;
  text-decoration: none;
  color: var(--text-placeholder);

`
