import React, { useRef } from "react";
import styled from "styled-components";
import { LAPTOP_MOBILE_POINTER } from '@tools/constants';
import { TypographyUI } from "@ui";
import { SwiperSlide } from "swiper/react";
import { useWindowSize } from "@tools/hooks";
import { Navigation } from "swiper";
import { SliderWrapper, SliderSwiper } from './CareerSlider';

import cardBg1 from '@images/career/our-values-card-bg-1.webp';
import cardBg2 from '@images/career/our-values-card-bg-2.webp';
import cardBg3 from '@images/career/our-values-card-bg-3.webp';
import cardBg4 from '@images/career/our-values-card-bg-4.webp';
import cardBg5 from '@images/career/our-values-card-bg-5.webp';
import cardBg6 from '@images/career/our-values-card-bg-6.webp';
import petFriendly from '@images/career/pet-friendly.webp';
import petTrace from '@images/career/pet-trace.webp';
import IconQuestion from '@images/shared/question.inline.svg';

import { Tooltip } from 'react-tooltip'

const CardAtmosphere = () => (
  <CardAtmosphereSd>
    <TypographyUI.H4Bold>Люди и атмосфера</TypographyUI.H4Bold>

    <CardSubtitle>
      Создаем комфортную среду для работы, ценим каждого члена команды и считаем, что успех бизнеса зависит в первую очередь от людей.
    </CardSubtitle>

    <CardAtmospherePet>
      <QuestionSd id="pet-icon" />

      <Tooltip
        anchorSelect="#pet-icon"
        place="bottom-start"
        wrapper={'span'}
        clickable
        noArrow
        style={{
          backgroundColor: "#3A4467CC",
          color: "var(--text-contrast)",
          borderRadius: '8px',
          maxWidth: '224px',
          padding: '12px',
        }}
      >
        <TooltipText>
          В наш офис можно приходить с питомцем, чтобы он не скучал дома один. Скорее всего он станет звездой наших соцсетей
        </TooltipText>
      </Tooltip>
    </CardAtmospherePet>
  </CardAtmosphereSd>
);

const CardFlexibility = () => (
  <CardFlexibilitySd>
    <TypographyUI.H4Bold>Гибкость и адаптивность</TypographyUI.H4Bold>

    <CardSubtitle>
      Мы не боимся новых процессов и не противостоим изменениям, постоянно двигаемся вместе с трендами на рынке IT.
    </CardSubtitle>
  </CardFlexibilitySd>
);

const CardEthics = () => (
  <CardEthicsSd>
    <TypographyUI.H4Bold>Этика и честность</TypographyUI.H4Bold>

    <CardSubtitle>
      Ценим честность во всех аспектах нашей работы. Для нас важно строить доверительные отношения внутри команды и с нашими клиентами, опираясь на высокие стандарты этики.
    </CardSubtitle>
  </CardEthicsSd>
);

const CardAmbition = () => (
  <CardAmbitionSd>
    <TypographyUI.H4Bold>Амбициозность и профессионализм</TypographyUI.H4Bold>

    <CardSubtitle>
      Мыслим масштабно, не ограничиваемся на достигнутом. Берем сложные проекты и несем ответственность за результат. Постоянно развиваем свои профессиональные навыки и поощряем наставничество.
    </CardSubtitle>
  </CardAmbitionSd>
);

const CardOpenness = () => (
  <CardOpennessSd>
    <TypographyUI.H4Bold>Открытость и свобода</TypographyUI.H4Bold>

    <CardSubtitle>
      Практикуем открытый подход в коммуникациях и не даем ложных ожиданий. Строим процессы, оставляя пространство для идей и их внедрения. Уважаем время на отдых и не беспокоим в нерабочие часы.
    </CardSubtitle>
  </CardOpennessSd>
);

const CardInnovation = () => (
  <CardInnovationSd>
    <TypographyUI.H4Bold>Инновации и креативность</TypographyUI.H4Bold>

    <CardSubtitle>
      Мы поощряем креативный подход и нестандартное мышление. Важно не только следовать трендам, но и создавать их, опережая время.
    </CardSubtitle>
  </CardInnovationSd>
);

export const CareerOurValues = () => {
  const { width } = useWindowSize();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const slidesPerView = (windowWidth: number) => {
    if (windowWidth >= 609) {
      return 1.5;
    }

    if (windowWidth >= 425) {
      return 1.2;
    }

    return 1.05;
  }

  const spaceBetween = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 20;
    }

    return 16;
  }

  return (
    <Section>
      <Header>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Наши ценности</TypographyUI.H2>
      </Header>

      <ListDesktop>
        <CardAtmosphere />

        <CardFlexibility />

        <CardEthics />

        <CardAmbition />

        <CardOpenness />

        <CardInnovation />
      </ListDesktop>

      <ListMobile>
        <SliderWrapper>
          <SliderSwiper
            modules={[Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              (swiper.params.navigation as any).prevEl = navigationPrevRef.current;
              (swiper.params.navigation as any).nextEl = navigationNextRef.current;
            }}
            slidesPerView={slidesPerView(width)}
            spaceBetween={spaceBetween(width)}
          >

            <SwiperSlide>
              <CardAtmosphere />
            </SwiperSlide>

            <SwiperSlide>
              <CardFlexibility />
            </SwiperSlide>

            <SwiperSlide>
              <CardEthics />
            </SwiperSlide>

            <SwiperSlide>
              <CardAmbition />
            </SwiperSlide>

            <SwiperSlide>
              <CardOpenness />
            </SwiperSlide>

            <SwiperSlide>
              <CardInnovation />
            </SwiperSlide>

          </SliderSwiper>
        </SliderWrapper>
      </ListMobile>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 3.75rem;
  }
`;

const Header = styled.div`
  width: 100%;
`

const ListDesktop = styled.div`
  display: none;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: grid;
    grid-template:
      'atmosphere atmosphere flexibility'
      'ethics ambition ambition'
      'openness openness innovation';
    gap: 1.25rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Card = styled.div`
  --padding: 1.5rem;
  --padding-bottom: 227px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-radius: 1.25rem;
  padding: var(--padding) var(--padding) var(--padding-bottom);
  height: 401px;

  background-color: var(--background-second, #E5F2FF);
  background-repeat: no-repeat;
  background-size: auto 200px;
  background-position: center bottom;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    --padding: 1.875rem;
    --padding-bottom: 268px;

    height: 438px;
    background-size: auto;
  }
`;

const CardAtmosphereSd = styled(Card)`
  position: relative;

  background-color: #EAEBE6;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-area: atmosphere;

    background-image: url(${cardBg1});
    background-size: cover;
    background-position: 70% bottom;

    padding-right: calc(100% - calc(var(--padding) + 350px));
  }
`

const CardFlexibilitySd = styled(Card)`

  background-color: var(--background-second, #E5F2FF);
  background-image: url(${cardBg2});
  background-size: 335px 205px;

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    background-position: center calc(100% - var(--padding));
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-area: flexibility;
  }
`

const CardEthicsSd = styled(Card)`
  background-color: var(--background-second, #E5F2FF);
  background-image: url(${cardBg3});
  background-position: calc(100% - var(--padding)) calc(100% - var(--padding));


  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    background-size: 142px 135px;
    background-position: calc(100% - var(--padding)) calc(100% - var(--padding));
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    background-size: 175px 167px;
    grid-area: ethics;
  }
`

const CardAmbitionSd = styled(Card)`
  background-color: var(--background-main-grey, #F5F8FB);
  background-image: url(${cardBg4});
  background-position: calc(100% - var(--padding)) calc(100% - var(--padding));

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    background-size: auto 110px;
    background-position: center calc(100% - var(--padding));
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-area: ambition;

    background-size: 100% 230px;
    padding-right: calc(100% - calc(var(--padding) + 550px));
  }
`

const CardOpennessSd = styled(Card)`
  background-color: var(--background-main-grey, #F5F8FB);
  background-image: url(${cardBg5});

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    background-size: auto 180px;
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-area: openness;

    background-size: 322px 408px;
    padding-right: calc(100% - calc(var(--padding) + 350px));
    background-position: calc(100% - var(--padding)) bottom;
  }
`

const CardInnovationSd = styled(Card)`
  background-color: var(--background-second, #E5F2FF);
  background-image: url(${cardBg6});
  background-position: calc(100% - var(--padding)) calc(100% - var(--padding));
  background-position: center calc(100% - var(--padding));

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    background-size: auto 170px;
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-area: innovation;

    background-size: 173px 175px;
  }
`

const CardAtmospherePet = styled.div`
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);

  height: 195px;
  width: 227px;

  background-image: url(${petTrace}), url(${petFriendly});
  background-repeat: no-repeat;
  background-position: center bottom, center top;
  background-size: 56px 56px, 223px 150px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    left: 50px;
    bottom: 66px;
    transform: translateX(0);
  }
`

const QuestionSd = styled(IconQuestion)`
  position: absolute;
  right: 0;
  top: 30px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  padding: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--accent-main, #007AFF);

  transition: 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #006BE0;
  }
`;

const CardSubtitle = styled(TypographyUI.Body1Regular)`
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: var(--text-secondary, #616A88);
`;

const ListMobile = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: none;
  }
`;

const TooltipText = styled(TypographyUI.CaptionRegular)`
  color: #fff;
  font-size: 0.75rem;
  line-height: 17px;
`
