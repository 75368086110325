import React, { useRef } from "react";
import styled from "styled-components";
import { LAPTOP_MOBILE_POINTER } from '@tools/constants';
import { TypographyUI } from "@ui";
import { FontMixinUI } from "@ui";
import { SwiperSlide } from "swiper/react";
import { useWindowSize } from "@tools/hooks";
import { Navigation } from "swiper";
import { SliderWrapper, SliderSwiper } from './CareerSlider';

import headerBg from '@images/career/we-create-trends-header-bg.webp'
import cardBg from '@images/career/we-create-trends-decor.svg'

import Anna_Serafimovskaya from '@images/career/Anna_Serafimovskaya.webp'
import Artem_Sakharov from '@images/career/Artem_Sakharov.webp'
import Evgeniy_Ermolov from '@images/career/Evgeniy_Ermolov.webp'
import Evgenia_Matveychikova from '@images/career/Evgenia_Matveychikova.webp'
import Ekaterina_Blistanova from '@images/career/Ekaterina_Blistanova.webp'
import Ivan_Bogoslovsky from '@images/career/Ivan_Bogoslovsky.webp'
import Olga_Sysoeva from '@images/career/Olga_Sysoeva.webp'
import Eldar_Akhmetyanov from '@images/career/Eldar_Akhmetyanov.webp'
import Yulia_Zhurba from '@images/career/Yulia_Zhurba.webp'

const list = [
  {
    img: Olga_Sysoeva,
    name: 'Ольга Сысоева',
    role: 'Финансовый директор',
    cite: 'Мой отдел помогает видеть бизнес прозрачнее, оценивать риски и реализовывать даже самые амбициозные планы',
  },
  {
    img: Ivan_Bogoslovsky,
    name: 'Иван Богословский',
    role: 'Директор по продукту',
    cite: 'Нравится, что здесь принимают твою индивидуальность и дают равные возможности для роста всем сотрудникам',
  },
  {
    img: Evgeniy_Ermolov,
    name: 'Евгений Ермолов',
    role: 'Технический директор',
    cite: 'В нашем отделе каждый может проявить инициативу и предложить новые идеи для развития компании',
  },
  {
    img: Yulia_Zhurba,
    name: 'Юлия Журба',
    role: 'Бизнес-партнер по персоналу',
    cite: 'Любой бизнес начинается с людей. Потому в первую очередь я забочусь о комфорте и особой атмосфере в команде',
  },
  {
    img: Ekaterina_Blistanova,
    name: 'Екатерина Блистанова',
    role: 'Арт-директор',
    cite: 'В работе я могу сосредоточиться на результате, не отвлекаясь на бюрократию и «перекладывание бумажек»',
  },
  {
    img: Artem_Sakharov,
    name: 'Артем Сахаров',
    role: 'Директор по продажам',
    cite: 'Главное в нашей работе — это честность по отношению к себе, бизнесу, который мы ведем, и к клиенту',
  },
  {
    img: Eldar_Akhmetyanov,
    name: 'Эльдар Ахметьянов',
    role: 'Руководитель группы продаж',
    cite: 'Открытость, честность и команда экспертов помогают работать в удовольствие, развиваться и строить долгосрочные отношения с клиентами',
  },
  {
    img: Anna_Serafimovskaya,
    name: 'Анна Серафимовская',
    role: 'Руководитель группы сопровождения',
    cite: 'Отдел сопровождения — это как мост между компанией и клиентами. Мы обеспечиваем доверительные, надежные и долгосрочные отношения',
  },
  {
    img: Evgenia_Matveychikova,
    name: 'Евгения Матвейчикова',
    role: 'Директор по маркетингу',
    cite: 'Моя команда — это креативные и талантливые профессионалы, способные превратить любую идею в реальность',
  },
];

export const CareerWeCreateTrends = () => {
  const { width } = useWindowSize();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const slidesPerView = (windowWidth: number) => {
    if (windowWidth >= 609) {
      return 2;
    }

    if (windowWidth >= 425) {
      return 1.3;
    }

    return 1.05;
  }

  const spaceBetween = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 20;
    }

    return 16;
  }

  return (
    <Section>
      <Header>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>
          Мы <TextAccent>создаем тренды</TextAccent> <br /> и не боимся менять правила игры
        </TypographyUI.H2>

        <HeaderDecor />
      </Header>

      <SectionDecor />

      <ListDesktop>
        {
          list.map(({ img, cite, name, role }) => (
            <Card key={name}>
              <CardImg src={img} alt="photo" />

              <CardMain>
                <CardTitle>{name}</CardTitle>

                <CardSubtitle>{role}</CardSubtitle>
              </CardMain>

              <CardCite>{cite}</CardCite>
            </Card>
          ))
        }
      </ListDesktop>

      <ListMobile>
        <SliderWrapper>
          <SliderSwiper
            modules={[Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              (swiper.params.navigation as any).prevEl = navigationPrevRef.current;
              (swiper.params.navigation as any).nextEl = navigationNextRef.current;
            }}
            slidesPerView={slidesPerView(width)}
            spaceBetween={spaceBetween(width)}
          >
            {list.map(({ img, cite, name, role }) => (
              <SwiperSlide key={name}>
                <Card>
                  <CardImg src={img} alt="photo" />

                  <CardMain>
                    <CardTitle>{name}</CardTitle>

                    <CardSubtitle>{role}</CardSubtitle>
                  </CardMain>

                  <CardCite>{cite}</CardCite>
                </Card>
              </SwiperSlide>
            ))}
          </SliderSwiper>
        </SliderWrapper>
      </ListMobile>
    </Section >
  );
}

const Section = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 0;
  }
`

const Header = styled.div`
  position: relative;

  padding-bottom: 94px;
  padding-right: 3rem;
  width: 100%;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    left: 0;
    top: 0;

    position: absolute;
    width: 562px;
    height: 293px;

    padding-bottom: 149px;
    padding-right: 42px;
    background-size: 184px 162px;
  }
`

const HeaderDecor = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;

  height: 113px;
  width: 128px;

  background-image: url(${headerBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotateY(180deg);

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: none;
  }
`

const SectionDecor = styled.div`
  display: none;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    position: absolute;
    right: 135.5px;
    bottom: 146.5px;
    z-index: 0;

    display: block;
    height: 162px;
    width: 184px;

    background-image: url(${headerBg});
    background-repeat: no-repeat;
    background-size: 100% 100%;

    transform: rotateY(0) translate(50%, 50%);
  }
`

const CardMain = styled.figcaption`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  padding: 0 19px 18px;
`;

const CardTitle = styled(TypographyUI.H4Bold)`
  color: var(--text-contrast, #fff);
`

const CardSubtitle = styled(TypographyUI.Body2Regular)`
  color: var(--text-contrast, #fff);
`

const CardImg = styled.img`
  display: inline-block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
`

const CardCite = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;

  opacity: 1;

  transition: 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  background-image: url(${cardBg});
  background-size: 78px 66px;
  background-position: 19px 54px;
  background-repeat: no-repeat;
  background-color: #00000080;

  ${FontMixinUI.IBMPlexSansNormal}
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  text-align: center;
  color: #fff;
  padding: 1rem 15% 5rem;

  @media (hover) {
    opacity: 0;
  }
`

const Card = styled.figure`
  position: relative;

  border-radius: 20px;
  height: 335px;
  background-color: #C4C0B6;

  overflow: hidden;

  @media (hover) {
    &:hover {
      ${CardCite} {
        opacity: 1;
      }
    }
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    height: 293px;
  }
`;

const ListDesktop = styled.div`
  display: none;

  ${Card}:first-child {
    grid-column-start: 3;
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.25rem;
  }
`


const ListMobile = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: none;
  }
`

const TextAccent = styled.span`
  color: var(--accent-main, #007aff);
`
