import React from "react";
import styled from "styled-components";
import { ButtonUI, TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { AdaptiveLinkCt } from "@components";
import bg from '@images/career/create-environment-bg.webp'

export const CareerWeCreateEnvironment = () => (
  <Section>
    <Content>
      <Header>
        <TypographyUI.H1 swichPoint={LAPTOP_MOBILE_POINTER}>Создаем комфортную цифровую среду для развития бизнеса</TypographyUI.H1>

        <TypographyUI.Body1Regular>Самозанятые.рф — экосистема сервисов для безопасной работы <br /> бизнеса с внештатными исполнителями.</TypographyUI.Body1Regular>
      </Header>

      <Footer>
        <AdaptiveLinkCt to="https://hh.ru/employer/4152303">
          <ButtonConnect>Присоединиться к нам</ButtonConnect>
        </AdaptiveLinkCt>

        <AdaptiveLinkCt to="https://t.me/selfworkteam">
          <ButtonTg>Телеграм-канал</ButtonTg>
        </AdaptiveLinkCt>
      </Footer>
    </Content>
  </Section>
)

const Section = styled.section`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: flex;
    align-items: center;
    justify-content: start;

    height: 481px;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 564px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 564px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.625rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    flex-direction: row;
  }
`;

const ButtonConnect = styled(ButtonUI)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 277px;
  }
`;

const ButtonTg = styled(ButtonUI.Secondary)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 233px;
  }
`;
