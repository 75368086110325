import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";

export const CareerFormSection = () => {
    const iframeRef = useRef(null);

    useEffect(() => {
        iframeRef.current.src = "https://forms.yandex.ru/cloud/67375c9c4936392b39f71555/?iframe=1";
        iframeRef.current.name = 'ya-form-67375c9c4936392b39f71555';

        const formListener = (e) => {
        };
        window.addEventListener('message', formListener);
        return () => {
            window.removeEventListener('message', formListener);
        };
    }, []);

    return <CareerForm>
        <script src="https://forms.yandex.ru/_static/embed.js"></script>
        <iframe ref={iframeRef} style={{ height: '900px', width: '100%', maxWidth: '375px', border: 'unset' }}></iframe>
    </CareerForm >

}

const CareerForm = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;
