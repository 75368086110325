import React, { useRef } from "react";
import styled from "styled-components";
import { TypographyUI } from "@ui";
import { CareerWrapper } from "./CareerWrapper";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import {
  SliderWrapper,
  SliderSwiper
} from './CareerSlider';
import Decor from '@images/career/work-with-us-decor.svg'
import bgMobileSvg from '@images/career/work-with-us-bg-mobile.svg'
import bgDesktopSvg from '@images/career/work-with-us-bg-desktop.svg'
import { StaticImage } from "gatsby-plugin-image";


export const CareerWorkWithUs = () => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <Section>
      <CareerWrapper>
        <Header>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Работать с нами — это</TypographyUI.H2>
        </Header>
      </CareerWrapper>

      <div className="list">
        <div className="item"></div>
      </div>

      <SliderWrapper>
        <SliderSwiper
          modules={[Navigation, Autoplay]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            (swiper.params.navigation as any).prevEl = navigationPrevRef.current;
            (swiper.params.navigation as any).nextEl = navigationNextRef.current;
          }}
          slidesPerView={'auto'}
          spaceBetween={20}
          loop={true}
          freeMode={true}
          speed={5000}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
        >

          <SwiperSlide>
            <Card width={350}>
              <StaticImage
                src="../../images/career/work-with-us-1.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                objectPosition="bottom"
                alt="background-img"
              />

              <StaticImage
                src="../../images/career/work-with-us-2.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={311.171875}>
              <StaticImage
                src="../../images/career/work-with-us-3.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={236}>
              <StaticImage
                src="../../images/career/work-with-us-4.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={236}>
              <StaticImage
                src="../../images/career/work-with-us-4.5.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={315}>
              <StaticImage
                src="../../images/career/work-with-us-5.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={315}>
              <StaticImage
                src="../../images/career/work-with-us-6.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={279}>
              <StaticImage
                src="../../images/career/work-with-us-7.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={236}>
              <StaticImage
                src="../../images/career/work-with-us-8.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card width={315}>
              <StaticImage
                src="../../images/career/work-with-us-9.webp"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                objectFit="cover"
                alt="background-img"
              />
            </Card>
          </SwiperSlide>
        </SliderSwiper>
      </SliderWrapper>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  padding-top: 44px;
  padding-bottom: 58px;
  background-color: #A9C3E4;
  background-image: url(${bgMobileSvg});
  background-size: contain;
  background-position: center;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 59px;

    padding-top: 61px;
    padding-bottom: 122px;

    background-image: url(${bgDesktopSvg});
  }

  &::after {
    content: '';

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 56px;
    width: 98px;
    background-image: url(${Decor});
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
      height: 102.45px;
      width: 178.46px;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }

  .gatsby-image-wrapper[data-main-image] {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  width: 100%;
  text-align: center;
`

const Card = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  height: 420px;
  width: ${({ width }) => width ? `${width}px` : '100%'};

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 300px;
  }
`
